import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, gray, grayscale, white } from 'src/styles/colors'

import BgOfertasPublicastablet from './assets/images/bg-ofertas-publicas-tablet.png'
import BgOfertasPublicasDesktopLG from './assets/images/bg-ofertas-publicas-desktop-lg.png'
import BgOfertasPublicasDesktopXL from './assets/images/bg-ofertas-publicas-desktop-xl.png'

type ToolTipProps = {
  tooltipOpen: string;
}

export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: 395px;
  background: ${orange.extra};

  &::before {
    background-image: none;
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
  }

  @media ${device.tablet} {
    background: ${orange.extra} url(${BgOfertasPublicastablet}) no-repeat center;
    background-size: initial;
    background-position: bottom 29px right 0;
    min-height: 340px;
  }

  @media ${device.desktopLG} {
    background:  ${orange.extra} url(${BgOfertasPublicasDesktopLG}) no-repeat;
    background-size: initial;
    background-position: bottom 15px right 0;
    min-height: 386px;
  }

  @media ${device.desktopXL} {
    background: ${orange.extra} url(${BgOfertasPublicasDesktopXL}) no-repeat right;
    background-size: initial;
    background-position: bottom 75px right 0;
    min-height: 531px;
  }

  > .container {
    padding-top: 60px;
    padding-bottom: 40px;
  } 
`

export const UL = styled.ul`
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
`

export const Schedule = styled.div`
  border-bottom: 1px solid ${gray['500']};
  p {
    width: 50%;

    &:first-child {
      @media ${device.tablet} {
        width: 75%;
      }
    }

    &:last-child {
      @media ${device.tablet} {
        width: 20%;
      }
    }
  }
`

export const ImgSize = styled.div`

  .gatsby-image-wrapper {
    @media ${device.tablet} {
      width: 336px;
    }
    @media ${device.desktopLG} {
      width: 376px;
    }
    @media ${device.desktopXL} {
      width: 456px;
    }
  }
`

export const ButtonSize = styled.button`
  background: ${orange.extra};
  color: ${white};

  &:hover {
    color: ${white};
    opacity: 0.8;
  }

  @media ${device.tablet} {
    min-width: 335px;
  }
  @media ${device.desktopLG} {
    min-width: 375px;
  }
  @media ${device.desktopXL} {
    min-width: 456px;
  }

  &:disabled {
    background: ${grayscale['300']};
    color: ${white};

    &:hover {
      background: ${grayscale['300']};
      color: ${white};
    }
  }
  &.gray {
    background: ${grayscale['300']};
  }
`

export const Tooltip = styled.div<ToolTipProps>` 
  background: ${grayscale['500']};
  border-radius: 8px;
  display: ${(props: ToolTipProps) => `${props.tooltipOpen}`};
  position: absolute;
  min-height: 108px;
  width: 90%;
  left: 5%;
  z-index: 1;
  top: -117px;

  @media ${device.desktopLG} {
    top: 75px;
  }
  @media ${device.desktopXXXL} {
    width: 70%;
  }

  &:before {
    content: '';
    background: ${grayscale['500']};
    border-radius: 2px;
    height: 16px;
    position: absolute;
    bottom: -4px;
    left: 48%;
    width: 16px;
    transform: rotate(45deg);

    @media ${device.desktopLG} {
      bottom: 97px;
    }
  }
`

export const ButtonModal = styled.div`
  padding: 0;
  position: relative;
`
