import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import Icon from 'src/components/UI/MarkdownIcon'
import Img from 'gatsby-image'
import { Modal } from 'src/components/Modal'
import OpenAccountOffersForm from 'src/components/UI/Forms/OpenAccountOffersForm'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'

import usePageQuery from './pageQuery'

import { DownloadsProps, ScheduleProps, OffersProps, ICheckIsProspect } from './types'
import { ContentHeader, UL, Schedule, ButtonSize, ImgSize, Tooltip, ButtonModal } from './style'

import { WIDTH_MD } from 'src/utils/breakpoints'

const Offers = ({ pageContext }: OffersProps) => {
  const data = usePageQuery()
  const domReady = useDomReady()
  const windowWidth = useWidth(500)
  const [ isTooltip, setIsTooltip ] = useState('none')
  const [ active, setActive ] = useState(false)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const checkIsProspect = () => {
    const prospectObj = pageContext.downloads.find((item: DownloadsProps) => {
        return item.nome.includes('Prospecto')
    })

    return {
      nome: prospectObj && prospectObj.nome,
      arquivoProspecto: prospectObj && prospectObj.arquivoProspecto,
    }
  }

  const { nome, arquivoProspecto }: ICheckIsProspect = checkIsProspect()

  const contentMetas = {
    slug: `/pra-voce/investimentos/ofertas-publicas/${pageContext.slug}/`,
    metaTitle: `${pageContext.titulo}` + ' | Inter',
    metaDescription: `${pageContext.subtitulo}`,
  }

  const openAccountFormModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountOffersForm customOrigin='Ofertas Públicas' closeModal={() => setIsModalOpen(false)} />
    </Modal>
  )

  function openModal () {
    setIsModalOpen(true)
    sendDatalayerEvent({
      section: 'dobra_5',
      element_action: 'click button',
      element_name: 'Efetuar reserva',
      section_name: 'Faça sua reserva',
      c_page: window.location.href,
    })
  }

  const isAcceptButtom = () => (
    windowWidth < WIDTH_MD
      ? (
        <a
          href='bancointer://investimentos/investir/ofertasPublicas'
          className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-lg-3 mt-xl-2'
          onClick={() => {
            setActive(true)
            sendDatalayerEvent({
              section: 'dobra_5',
              element_action: 'click button',
              element_name: 'Efetuar reserva',
              section_name: 'Faça sua reserva',
              c_page: window.location.href,
            })
          }}
        >
          Efetuar Reserva
        </a>
      )
      : (
        <ButtonSize
          onClick={openModal}
          className='btn btn--lg rounded-2 fs-14 fw-600 text-none mt-lg-3'
        >
          Efetuar Reserva
        </ButtonSize>
      )
  )

  return (
    <Layout pageContext={contentMetas}>
      {openAccountFormModal}
      <ContentHeader>
        <div className='container'>
          <div className='row justify-content-center justify-content-md-between align-items-center'>
            <div className='col-12 col-md-7 col-xl-6'>
              <span className='fs-16 lh-19 fs-lg-20 lh-lg-24 fs-xl-24 lh-xl-28 text-white mb-3 d-block text-uppercase fw-600'>
                Material Publicitário
              </span>
              <h2 className='fs-32 lh-37 fs-lg-40 lh-lg-47 fs-xl-48 lh-xl-56 text-white'>
                {pageContext.titulo}
              </h2>
              <p className='fs-18 lh-22 mt-4 text-white mb-0'>
                {pageContext.subtitulo}
              </p>
            </div>
          </div>
        </div>
      </ContentHeader>

      <section className='bg-grayscale--100 py-3'>
        <div className='container'>
          <div className='row justify-content-start justify-content-xl-center align-items-center'>
            <div className='col-12 d-flex justify-content-start align-items-center'>
              <div className='mr-2 mr-lg-3'>
                <Icon width='32' height='32' icon='action/stats/warning-2' directory='v2' />
              </div>
              <p className='fs-20 lh-24 text-grayscale--400 mb-0 fw-600'>
                {nome && arquivoProspecto
                  ? 'Leia o Prospecto antes de aceitar a oferta, em especial a seção Fatores de Risco.'
                  : 'Leia os documentos da oferta antes de aceitá-la.'
                }
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='py-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ImgSize>
                <Img fluid={data.UsefulFiles.fluid} alt='Cliente acessando documentos pelo notebook' />
              </ImgSize>
            </div>
            <div className='col-12 col-md-6 col-lg-6 offset-lg-1 col-xl-5 offset-xl-2'>
              <h3 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-md-50 text-grayscale--500 mt-4 mt-md-0'>
                Arquivos úteis
              </h3>
              <p className='fs-18 lh-22 text-grayscale--500'>
                Leia o prospecto antes de aderir a oferta, em especial a sessão de fatores de risco.
                Acesse o app e faça a solicitação da sua reserva.
              </p>
              <UL className='mt-3 mt-md-4'>
                {pageContext.downloads.map((item: DownloadsProps, index: number) => {
                  return (
                    <li key={index} className='d-flex align-items-center justify-content-start mb-3'>
                      <div className='mr-3'>
                        <Icon width='24' height='24' icon='navigation/downl' directory='v2' />
                      </div>
                      {item.arquivoProspecto
                        ? (
                          <a
                            onClick={() => {
                              setActive(true)
                              sendDatalayerEvent({
                                section: 'dobra_2',
                                element_action: 'click button',
                                element_name: item.nome,
                                section_name: 'Arquivos úteis',
                                redirect_url: item.url,
                              })
                            }}
                            href={item.url} title='Arquivos úteis'
                            target='_blank' rel='noreferrer'
                          >{item.nome}
                          </a>
                        )
                      : (
                        <a
                          href={item.url} title='Arquivos úteis'
                          target='_blank' rel='noreferrer'
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_2',
                              element_action: 'click button',
                              element_name: item.nome,
                              section_name: 'Arquivos úteis',
                              redirect_url: item.url,
                            })
                          }}
                        >{item.nome}
                        </a>
                      )
                    }
                    </li>
                    )
                  },
                )}
              </UL>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container pt-2 pb-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-md-50 text-grayscale--500 text-center'>
                Cronograma da Oferta
              </h3>
            </div>
            <div className='col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>
              {pageContext.cronograma.map((item: ScheduleProps, index: number) => {
                return item.valor && item.valor.length > 0 ? (
                  <Schedule key={index} className='d-flex align-items-center justify-content-between pt-4 mb-1'>
                    <p className='fs-16 fs-md-18 lh-19 lh-md-22 text-grayscale--500 fw-600 pl-3'>{item.nome}</p>
                    <p className='fs-18 lh-22 text-grayscale--500 text-right pr-3'>{item.valor}</p>
                  </Schedule>) : <></>
                },
              )}
            </div>
          </div>
        </div>
      </section>

      <section className='bg-gray'>
        <div className='container py-5'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-2 order-md-last'>
              <ImgSize>
                <Img fluid={data.StayTuned.fluid} alt='Cliente acessando app do Inter e tomando suco' />
              </ImgSize>
            </div>
            <div className='col-12 col-md-6 col-xl-5'>
              <h3 className='fs-24 fs-md-32 fs-xl-40 lh-30 lh-md-40 lh-xl-50 mt-4 mt-md-0'>Fique atento às regras</h3>
              <p className='fs-16 lh-19 text-grayscale--500'>
                Só serão aceitas as solicitações de reservas de clientes que tiverem, na data da reserva, assinado o Termo de Adesão ao Contrato de Intermediação e
                Subscustódia e realizado o Teste do Perfil do Investidor (Suitability).
              </p>
              <p className='fs-16 lh-19 text-grayscale--500'>
                Para efetivação do pedido de reserva é necessário que o cliente disponha de Limite Operacional compatível a 100% do valor reservado,
                conforme Política de Concessão de Limites e Gestão de Riscos da Inter DTVM. Caso contrário, a reserva não será efetivada.
              </p>
              <p className='fs-16 lh-19 text-grayscale--500'>
                Se o cliente realizar mais de uma reserva, as quantidades serão acrescidas às reservas anteriores e a Modalidade e Preço informados serão
                correspondentes à primeira reserva.
              </p>
              <p className='fs-16 lh-19 text-grayscale--500'>
                Uma vez realizado, o pedido de reserva é irrevogável e irretratável. O investidor só poderá desistir de sua adesão caso haja modificação nos termos da Oferta.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='py-5'>
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ImgSize>
                <Img fluid={data.UsefulFiles.fluid} alt='Cliente acessando documentos pelo notebook' />
              </ImgSize>
            </div>
            <div className='col-12 col-md-6 col-lg-5'>
              <h3 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-md-50 text-grayscale--500 mt-4 mt-md-0'>
                Faça sua reserva
              </h3>
              {
                nome && arquivoProspecto
                  ? (
                    <p className='fs-18 lh-22 text-grayscale--500 mb-md-4'>
                      Leia o
                      <a
                        onClick={() => {
                          setActive(true)
                          sendDatalayerEvent({
                            section: 'dobra_5',
                            element_action: 'click button',
                            element_name: 'prospecto',
                            section_name: 'Faça sua reserva',
                            redirect_url: pageContext.linkProspecto,
                          })
                        }}
                        href={pageContext.linkProspecto} title='Leia o prospecto antes de aceitar'
                        target='_blank' rel='noreferrer' className='fw-600'
                      > prospecto
                      </a> antes de aceitar a oferta, em especial a sessão de fatores de risco.
                      Acesse o app e faça a solicitação da sua reserva.
                    </p>
                  )
                  : isAcceptButtom()
              }
              <ButtonModal className='col-12'>
                { active
                  ? isAcceptButtom()
                  : (
                    <>
                      {
                        nome && arquivoProspecto &&
                          <ButtonSize
                            className='btn btn--lg rounded-2 fs-14 fw-600 text-none mt-lg-3 gray'
                            onMouseOver={() => setIsTooltip('block')}
                          >
                            Efetuar Reserva
                          </ButtonSize>
                      }
                    </>
                  )
                }
                <Tooltip
                  className='text-white pt-3 pb-2 px-3 fs-10 lh-12'
                  tooltipOpen={isTooltip}
                >
                  <span className='fs-12 lh-15 fw-600 text-white mb-2 d-block'>Atenção</span>
                  <p className='fs-12 lh-15 text-white mb-0'>
                    Antes de efetuar sua reserva, é necessário que você acesse o prospecto.
                  </p>
                  <span
                    onClick={() => setIsTooltip('none')}
                    className='fs-12 lh-15 fw-600 text-orange--extra mb-2 d-block float-right mt-2 mb-0 cursor-pointer'
                  >Entendi
                  </span>
                </Tooltip>
              </ButtonModal>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Offers
