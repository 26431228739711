import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      UsefulFiles: imageSharp(fluid: {originalName: { regex: "/arquivos-uteis/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      StayTuned: imageSharp(fluid: {originalName: { regex: "/fique-atento-as-regras/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }   
    }
  `)

  return pageQuery
}

export default PageQuery
